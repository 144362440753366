// Projects.js
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Services() {

  const { t } = useTranslation();


  useEffect(() => {
  }, [t]);


  return (
    <>
      <Helmet>
        <title>Our Services - Spatial Architecture</title>
        <meta
          name="description"
          content="Discover the range of services offered by Spatial Architecture. From sustainable design to project management, we provide comprehensive architectural solutions."
        />
        <meta property="og:title" content="Our Services - Spatial Architecture" />
        <meta
          property="og:description"
          content="Discover the range of services offered by Spatial Architecture. From sustainable design to project management, we provide comprehensive architectural solutions."
        />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/services.png"
        />
        <meta property="og:url" content="https://spatialarchitecture.gr/services" />
      </Helmet>
      <div className="container flex-grow-1" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center',marginTop: 30 ,   marginBottom: 30 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '15px',
          lineHeight: '1.67em',
          fontWeight: 300,
          marginLeft: 20 ,
          marginRight: 20

        }}>
          <p style={{ fontWeight: 500 }}><strong>SpatialArchitecture + Design</strong>  </p>
          <p style={{ textAlign: 'justify' }}  >
            {t('office')}<strong style={{ fontWeight: 'bold' }}>SpatialArchitecture + Design</strong>, {t("officeDescr")}
          </p>

          <p style={{ fontWeight: 500 }} >  <strong> {t('SP_archStudy')} </strong> </p>
          <p style={{ textAlign: 'justify' }} >
            {t('studyDesc')}
          </p>
          <p style={{ textAlign: 'justify' }} >
            {t('studyDesc1')}
          </p>

          <p style={{ fontWeight: 500 }} ><strong>{t('SP_interior')}</strong> </p>
          <p style={{ textAlign: 'justify' }} >
            {t('intDesignDesc')}
          </p>

          <p style={{ fontWeight: 500 }} > <strong>{t('SP__app')}</strong> </p>
          <p style={{ textAlign: 'justify' }} >
            {t('intDesignDesc1')}
          </p>

          <p style={{ fontWeight: 500 }}> <strong>{t('SP_brand')}</strong> </p>
          <p style={{ textAlign: 'justify' }} >
            {t('brandDesc')}
          </p>

          <img
            src="/services.jpg"
            alt="Spatial Logo1"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>

      </div>
    </>

  );
}

export default Services;
