import React, { useEffect, useState } from "react";
import GoogleMapCustom from "./GoogleMaps";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast } from "react-bootstrap";

function ContactUs() {
    useEffect(() => {
        document.title = "ΕΠΙΚΟΙΝΩΝΙΑ";
    }, []);

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [notification, setNotification] = useState(null);

    const showNotification = (type, message) => {
        setNotification({ type, message });
        setTimeout(() => {
            setNotification(null);
        }, 30000); // Hide the notification after 30 seconds
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
        console.log(recaptchaValue);
    };

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('data')
        console.log(formData)
        if (
            formData.name &&
            formData.email &&
            formData.message
        ) {
            try {
                const response = await fetch(
                    "https://us-central1-spatialarch-be686.cloudfunctions.net/sendEmail",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(formData),
                    }
                );

                if (response.ok) {
                    showNotification("success", "Email sent successfully.");
                } else {
                    showNotification(
                        "error",
                        "Email not sent. Please contact us via phone"
                    );
                }
            } catch (error) {
                console.error("An error occurred:", error);
                showNotification(
                    "error",
                    "Email not sent. Please contact us via phone"
                );
            }
        } else {
            showNotification("Light", "Please fill all fields.");
        }
    };

    return (
        <div className="container flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center'}}>
            <div className="row">
                {/* Column for Google Map */}
                <div className="col-md-6 col-sm-12 mb-5">
                    <div className="map-container" style={{ width: '100%', minHeight: '400px' }}>
                        <GoogleMapCustom />
                    </div>
                </div>
                {/* Column for Form */}
                <div className="col-md-6">
                    <div className="map-container">
                        <div className="form-container ">
                            <div className="communication-form ">
                                <form onSubmit={handleSubmit} style={{ border: '1px solid gray', padding: '20px', minHeight: '450px' }}>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <label style={{ marginTop: 20, marginLeft: 30 }} htmlFor="name" className="form-label">FULL NAME <span style={{ fontWeight: 'bold' }}> (*) </span></label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" className="form__field" onChange={handleChange} value={formData.name} placeholder="Name" name="name" id='name' required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <label style={{ marginTop: 20, marginLeft: 30 }} htmlFor="email" className="form-label">E-MAIL <span style={{ fontWeight: 'bold' }}> (*) </span></label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" className="form__field" onChange={handleChange} value={formData.email} name="email" id='email' required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <label style={{ marginTop: 20, marginLeft: 30 }} htmlFor="message" className="form-label">COMMENTS <span style={{ fontWeight: 'bold' }}> (*) </span></label>
                                            </div>
                                            <div className="col-md-6">
                                                <textarea className="form__field" onChange={handleChange} value={formData.message} name="message" id='message' required style={{  minHeight: '100px' }}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <label style={{ marginTop: 20, marginLeft: 30 }} htmlFor="recaptcha" className="form-label"><span style={{ fontWeight: 'bold' }}>  </span></label>
                                            </div>
                                            <div className="col-lg-7 col-md-8">
                                                <ReCAPTCHA
                                                    style={{ width: '50%', transform: 'scale(0.85)' }}
                                                    sitekey="6LdiaPcpAAAAAAhqs7zID61gABX-J4d47ZPHtAvf"
                                                    onChange={handleRecaptchaChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-5" style={{ display: 'flex', justifyContent: 'flex-start' }}></div>
                                            <div className="col-md-7">
                                                <button
                                                    disabled={!recaptchaValue}
                                                    type="submit"
                                                    style={{ backgroundColor: '#a88e6a', color: 'white', width: '100px' }}
                                                    className="btn"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {notification && (
                                    <Toast bg={notification.type} style={{ marginTop: 10 }}>
                                        <Toast.Header>
                                            <img className="rounded me-2" alt="" />
                                            <strong className="me-auto">Email Info</strong>
                                            <small>{new Date().toLocaleTimeString()}</small>
                                        </Toast.Header>
                                        <Toast.Body>{notification.message}</Toast.Body>
                                    </Toast>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ContactUs;
