import React from "react";
import { Container } from "react-bootstrap";
import ArchitectureSlider from "./SwiperCarousel";
import { Helmet } from "react-helmet";

function Dashboard() {


  const images = [
  
    {
      src: "spacial_office.jpg",
      alt: "SP10_Office",
      name: "SP10_Office"
    },
    {
      src: "cine.png",
      alt: "Cine_Paris",
      name: "Cine_Paris"
    },
    {
      src: "lux_kitchen.jpg",
      alt: "Concept_Villa_01",
      name: "Concept_Villa_01"
    },
    {
      src: "farmhouse.png",
      alt: "Corfu_Farmhouse",
      name: "Corfu_Farmhouse"
    },

    // ... more images
  ];

  return (
    <>
      <Helmet>
        <title>Spatial Architecture </title>
        <meta
          name="description"
          content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
          />
        <meta property="og:title" content="Spatial Architecture" />
        <meta
          property="og:description"
          content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
          />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/table.png"
        />
        <meta property="og:url" content="https://spatialarchitecture.gr/" />
      </Helmet>
      <Container className="flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center'}}>
        <div  style={{ marginLeft: 20 , marginRight: 20}}>
          <ArchitectureSlider   images={images} />
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
