import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from './util/ProjectData'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
function ProjectPage() {
  const { projectId } = useParams();

  const { t, i18n } = useTranslation();
  const myProject = projectsData.find(proj => proj.title === projectId);

  return (
    <>
    <Helmet>
      <title>Spatial Architecture </title>
      <meta
        name="description"
        content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
        />
      <meta property="og:title" content="Spatial Architecture" />
      <meta
        property="og:description"
        content="Discover the cutting-edge designs of Spatial Architecture, blending innovation with practicality. Our expertise in sustainable architecture and modern design transforms spaces into functional works of art."
        />
      <meta
        property="og:image"
        content="https://github.com/gdimitropoulos/public-photos/blob/main/table.png"
      />
      <meta property="og:url" content="https://spatialarchitecture.gr/" />
    </Helmet>
    <Container className="flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center',textAlign: 'justify' }}>
      <div className='row'  style={{ margin: 20}} >
        <div className='col-12' >

            <h1 className='mb-5 mt-5'> { myProject.title}</h1>
            {myProject.description.map((paragraph, index) => (
            <p className='descr-text' key={index}>{t(paragraph)}</p>
      ))}

        {myProject.photoBy &&  <h6> {t('photography')}: { t(myProject.photoBy)} </h6> } 
        </div>


        <Row  >
            {myProject.images.map((image, index) => (
              <Col xs={12} md={6} lg={4} className="mb-4 " key={index}>
                    <img
                      src={image.src}
                      alt={image.title}
                      className="project-image"
                    />

              </Col>
            ))}
          </Row>

      </div>
    </Container>
  </>
  );
}

export default ProjectPage;