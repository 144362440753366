const projectsData = [
  {
    clickable: true,
    src: "spacial_office.jpg",
    title: "SP10_Office",
    description: [
      `project_office` ,
    ],
    photoBy: `myrto`,
    images: [
      { src: "/sp_office/00.jpg", title: "sp_office" },
      { src: "/sp_office/01.jpg", title: "sp_office" },
      { src: "/sp_office/02.jpg", title: "sp_ofsfice" },
      { src: "/sp_office/03.jpg", title: "sp_office" },
      { src: "/sp_office/04.jpg", title: "sp_office" },
      { src: "/sp_office/file.jpg", title: "sp_office" },
      { src: "/sp_office/06.jpg", title: "sp_office" },
      { src: "/sp_office/07.jpg", title: "sp_office" },
      { src: "/sp_office/08.jpg", title: "sp_office" },
      { src: "/sp_office/09.jpg", title: "sp_office" },
      { src: "/sp_office/10.jpg", title: "sp_office" },
      { src: "/sp_office/11.jpg", title: "sp_office" },
      { src: "/sp_office/12.jpg", title: "sp_office" },
      { src: "/sp_office/13.jpg", title: "sp_office" },
      { src: "/sp_office/14.jpg", title: "sp_office" },
      { src: "/sp_office/15.jpg", title: "sp_office" },
      { src: "/sp_office/16.jpg", title: "sp_office" },
      { src: "/sp_office/17.jpg", title: "sp_office" },
      { src: "/sp_office/18.jpg", title: "sp_office" },
      { src: "/sp_office/19.jpg", title: "sp_office" },
      { src: "/sp_office/20.jpg", title: "sp_office" },
      { src: "/sp_office/21.jpg", title: "sp_office" },
    ],
  },
  {
    clickable: true,
    src: "cine.png",
    title: "Cine_Paris",
    description: [
      `project_cine` ,
    ],
    images: [
      { src: "/cp/COLLAGES/COLLAGE1.jpg", title: "Cine_Paris" },
      { src: "/cp/COLLAGES/COLLAGE2.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/1.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/2.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/3.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/4.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/5.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/6.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/7.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/8.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/9.jpg", title: "Cine_Paris" },
      { src: "/cp/PHOTOS/10.jpg", title: "Cine_Paris" },
      { src: "/cp/RENDERS/1.png", title: "Cine_Paris" },
      { src: "/cp/RENDERS/2.png", title: "Cine_Paris" },
      { src: "/cp/RENDERS/3.png", title: "Cine_Paris" },
      { src: "/cp/RENDERS/4.png", title: "Cine_Paris" },
      { src: "/cp/RENDERS/5.jpg", title: "Cine_Paris" },
    ],
  },
  { src: "lux_kitchen.jpg", title: "Concept_Villa_01", clickable: false },
  { clickable: false, src: "farmhouse.png", title: "Corfu_Farmhouse" },
  // Add more images as needed
];

export default projectsData;
