import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import elTranslation from './locales/el/translation.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  el: {
    translation: elTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'el', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    // Add other options as needed
  });

export default i18n;
