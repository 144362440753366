// Router.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css'; 
import NavbarMenu from './components/Navbar';
import './i18n'; 
import { routes } from './routes';

function AppRouter() {
    
    return (
        <Router>
            <div className="d-flex flex-column min-vh-100">
                <NavbarMenu />
                    <Routes>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                            />
                        ))}
                    </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default AppRouter;
