import React, { useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import projectsData from "./util/ProjectData";



function Projects() {
  useEffect(() => {
  }, []);

  const handleLinkClick = (e,canNavigate) => {
    console.log(canNavigate)
    if (!canNavigate) {
      e.preventDefault();
    }
  }

  return (
    <>
      <Helmet>
        <title>Our Projects - Spatial Architecture</title>
        <meta
          name="description"
          content="Explore the diverse range of projects completed by Spatial Architecture. From residential to commercial, discover how we blend innovation with practicality."
        />
        <meta property="og:title" content="Our Projects - Spatial Architecture" />
        <meta
          property="og:description"
          content="Explore the diverse range of projects completed by Spatial Architecture. From residential to commercial, discover how we blend innovation with practicality."
        />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/projects.png"
        />
        <meta property="og:url" content="https://spatialarchitecture.gr/projects" />
      </Helmet>
      <Container className="flex-grow-1">

        <div >
          <Row className="justify-content-center">
            {projectsData.map((image, index) => (
              <Col xs={12} md={6} className="mb-4" key={index}>
                 <Link onClick={(e)=>handleLinkClick(e,image.clickable)} to={`/project/${image.title}`} className="image-container">
                    <img
                      src={image.src}
                      alt={image.title}
                      className="project-image"
                    />
                    <div className='image-title'>
                      <p>{image.title}</p>
                    </div>
                  </Link>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Projects;
