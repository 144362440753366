import React, {  useState } from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  FullscreenControl
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

const GoogleMapCustom = () => {
  const { t } = useTranslation();

  const containerStyle = {
    height: " 450px",
    width: "100%"

  };

  const center = { lat: 37.978242, lng: 23.742568 };
  const [markerPosition] = useState(center);

  const handleClick = () => {
    const url = `https://www.google.com/maps?q=${center.lat},${center.lng}`;
    window.open(url, '_blank');
  };

  const handleDirectionsClick = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;
    window.open(url, '_blank');
  };


  const CustomOverlay = ({ handleClick, handleDirectionsClick }) => (
    <div style={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      backgroundColor: 'white',
      padding: '5px',
      border: '1px solid black',
      zIndex: 1000
    }}>
      <div className='row' style={{ marginBottom: 0, marginTop: 10, margin: 3 }}>
        <div className='col-md-7'>
          <h6 style={{ lineHeight: 0.5, marginTop: 10 }}> {t('address')}</h6>
          <span style={{ fontSize: 10 }}> {t('loc')} </span>
          <br />
          <p className='underline-text' style={{ marginTop: 20, lineHeight: 0.5, fontSize: 12 }} onClick={handleClick}>View Larger Map</p>
        </div>
        <div className='col-md-5 text-center underline-text'>
          <i style={{ fontSize: 40, cursor: 'pointer' }} className="bi bi-sign-merge-left"></i>
          <span style={{ marginRight: 5, textDecoration: 'none' }} onClick={handleDirectionsClick}>Directions</span>
        </div>
      </div>
    </div>
  );



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAElZoOLpZAmgN0zh34Xifrhjh8py8xJ7U"
  })

  const [map, setMap] = React.useState(null)



  const onLoad = React.useCallback(function callback(map) {
    // Initialize the map
    map.setZoom(20)

  }, []);


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  const customMarkerIcon = {
    url: 'table.png', // URL to our custom marker icon
    scaledSize: { width: 40, height: 40 } 
  };

  const mapOptions = {
    disableDefaultUI: false, 
    zoomControl: true, 
    mapTypeControl: false 
  };

  return isLoaded ? (
    <GoogleMap
      ref={map}
      mapContainerStyle={containerStyle}
      center={center}
      options={mapOptions}
      onLoad={onLoad}
      zoom={20}
      
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <>
      
        <Marker position={markerPosition} icon={customMarkerIcon} />
        <CustomOverlay handleClick={handleClick} handleDirectionsClick={handleDirectionsClick} />

      </>
    </GoogleMap>
  ) : <></>

};

export default GoogleMapCustom;
