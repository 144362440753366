import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function NavbarMenu() {

  const [activeLink, setActiveLink] = useState('/');
  const [selectedLanguage, setSelectedLanguage] = useState('el');

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  const { t, i18n } = useTranslation();


// Update body font family based on language

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    updateBodyFontFamily(lng); 

  };

  useEffect(() => {
    updateBodyFontFamily(selectedLanguage); 
  }, [selectedLanguage]);

  const updateBodyFontFamily = (lng) => {
    const body = document.querySelector('body');
    if (lng === 'en') {
      body.style.fontFamily = 'Outfit';
    } else if (lng === 'el') {
      body.style.fontFamily = 'Manrope';
    }
  };
  return (
    <>

      <Navbar
        style={{
          minHeight: '80px',
          marginBottom: '10px'
        }}
        className="text-white"
        expand="lg"
      >

        <Row className="lang-row" style={{ width: '100%' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <span
              className={`language-button custom-pointer ${selectedLanguage === 'en' ? 'active-language' : ''}`}
              title="English (EN)"
              onClick={() => changeLanguage('en')}
            >
              en
            </span>
            <span
              className={`language-button custom-pointer ${selectedLanguage === 'el' ? 'active-language' : ''}`}
              title="Ελληνικά (ΕΛ)"
              onClick={() => changeLanguage('el')}
            >
              ελ
            </span>
          </div>
        </Row>
        <Container className='nav-cont' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Navbar.Toggle aria-controls="navbar" style={{ marginLeft: "2%" }} />
          <Navbar.Brand as={Link} to="/" onClick={() => handleNavLinkClick('/')} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="/spatial.jpg"
              alt="Spatial Logo"
              className="responsive-image2"

            />
            <img
              src="/arc.jpg"
              alt="Arc Logo"
              className="responsive-image"
            />
          </Navbar.Brand>
          <Navbar.Collapse id="navbar" className="justify-content-end my-navbar">
            <Nav className="mr-auto nav-tabs">
              <Link className={`nav-link ${activeLink === '/' ? 'active' : ''}`} to="/" onClick={() => handleNavLinkClick('/')}>{t('nav.home')}</Link>
              <Link className={`nav-link ${activeLink === '/projects' ? 'active' : ''}`} to="/projects" onClick={() => handleNavLinkClick('/projects')}>{t('nav.projects')}</Link>
              <Link className={`nav-link ${activeLink === '/services' ? 'active' : ''}`} to="/services" onClick={() => handleNavLinkClick('/services')}>{t('nav.services')}</Link>
              <Link className={`nav-link ${activeLink === '/about' ? 'active' : ''}`} to="/about" onClick={() => handleNavLinkClick('/about')}>{t('nav.team')}</Link>
              <Link className={`nav-link ${activeLink === '/contact' ? 'active' : ''}`} to="/contact" onClick={() => handleNavLinkClick('/contact')}>{t('nav.contact')}</Link>
              <span className="nav-divider">|</span>
              <div className='lang-divider' >
                <span
                  className={`language-button custom-pointer ${selectedLanguage === 'en' ? 'active-language' : ''}`}
                  title="English (EN)"
                  onClick={() => changeLanguage('en')}
                >
                  en
                </span>
                <span
                  className={`language-button custom-pointer ${selectedLanguage === 'el' ? 'active-language' : ''}`}
                  title="Ελληνικά (ΕΛ)"
                  onClick={() => changeLanguage('el')}
                >
                  ελ
                </span>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavbarMenu;