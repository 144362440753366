import React, { useEffect, useState } from "react";
import { Container} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';


function AboutUs() {
  const { t } = useTranslation();
  const [ title , setTitle] = useState(t("nav.team"))

  useEffect(() => {
    setTitle(t("nav.team"));
  }, [t]);

  return (
    <>
        <Helmet>
        <title>About Us - Spatial Architecture</title>
        <meta
          name="description"
          content="Learn more about Spatial Architecture, our mission, and our team of innovative architects dedicated to creating sustainable and modern designs."
        />
        <meta property="og:title" content="About Us - Spatial Architecture" />
        <meta
          property="og:description"
          content="Learn more about Spatial Architecture, our mission, and our team of innovative architects dedicated to creating sustainable and modern designs."
        />
        <meta
          property="og:image"
          content="https://github.com/gdimitropoulos/public-photos/blob/main/about.png"
        />
        <meta property="og:url" content="https://spatialarchitecture.gr/about" />
      </Helmet>
      <Container className="flex-grow-1" style={{   display: 'flex' , flexDirection:'column' , justifyContent: 'center'}}>
      <div
          style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 100,
          marginBottom: 30
        }}
      >
        <div className="col-md-6" style={{ padding: "10px" }}>
          <div className="map-container">
            <img
              src="/DSC_0516.jpg"
              alt="Spatial Logo1"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div
          className="col-md-3"
          style={{ flex: "1 1 300px" , marginLeft: "1vw" }}
        >
          <div className="map-container">
            <p style={{ fontWeight: 500 , marginTop: 15 }}>
              {" "}
              <strong>SP__</strong>{" "}
            </p>
            <p style={{ fontSize: 17, textAlign: "justify" }}>
            {t("aboutUs")}                        
            </p>
          </div>
        </div>
      </div>
    </Container>
    </>
  );
}

export default AboutUs;
